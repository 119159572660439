<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SingleEditableDetail from "./singleEditableDetail.vue";
const store = useStore();
const user = computed(() => {
  return store.getters["auth/userName"];
});
const cards = [
  {
    title: "Personal Information",
    fields: [
      { title: "Forename:", value: user.value?.profile.first_name },
      { title: "Middle Name:", value: user.value?.profile.middle_name },
      { title: "SurName:", value: user.value?.profile.last_name },
      {
        title: "Pronouns:",
        value:
          user.value?.profile.gender === "male"
            ? "He/Him"
            : user.value?.profile.gender === "female"
            ? "She/Her"
            : "Other",
      },
    ],
  },
  {
    title: "Basic Contact Information",
    fields: [
      { title: "Telephone Number:", value: user.value?.profile.home_phone },
      { title: "Mobile Number:", value: user.value?.profile.mobile_phone },
      { title: "Work Telephone:", value: user.value?.profile.work_phone },
      {
        title: "Personal Email:",
        value: user.value?.email,
      },
    ],
  },
  {
    title: "Basic Address Information",
    fields: [
      { title: "Country:", value: user.value?.profile.country },
      { title: "Date of Birth:", value: user.value?.profile.dob },
      { title: "Address Line 1:", value: user.value?.profile.address_line_1 },
      { title: "Address Line 2:", value: user.value?.profile.address_line_2 },
      { title: "Town/City:", value: user.value?.profile.city },
      {
        title: "Postcode:",
        value: user.value?.profile.zip_code,
      },
      {
        title: "County:",
        value: user.value?.profile.county,
      },
    ],
  },
  {
    title: "Basic Medical Information",
    fields: [
      { title: "NHS Number:", value: user.value?.profile.nhs_number },
      { title: "NHS Card Status:", value: user.value?.profile.nhs_card },
      {
        title: "Covid-19 Vaccination 1:",
        value: user.value?.profile.address_line_1,
      },
      {
        title: "Covid-19 Vaccination 2:",
        value: user.value?.profile.address_line_2,
      },
      {
        title: "Covid-19 Vaccination Booster:",
        value: user.value?.profile.city,
      },
    ],
  },
];
</script>

<template>
  <div class="flex-grow">
    <div class="bg-gray-200 w-full h-full p-5">
      <p class="text-xl font-medium mb-5">User Profile</p>
      <div class="grid grid-cols-2 gap-4">
        <div
          class="bg-white rounded-md p-4 flex flex-col"
          v-for="(item, index) in cards"
          :key="item.index"
        >
          <p class="text-md font-medium mb-5">{{ item.title }}</p>
          <div class="flex flex-grow">
            <div class="image" v-if="index === 0">
              <span
                class="
                  material-icons
                  mr-3
                  text-orange
                  border border-teal
                  text-9xl
                  cursor-pointer
                  rounded-full
                "
              >
                account_circle
              </span>
              <p class="text-teal text-center">Edit</p>
            </div>
            <div class="flex flex-col w-full">
              <div
                class="flex flex-grow justify-between items-center"
                v-for="info in item.fields"
                :key="info.index"
              >
                <SingleEditableDetail :info="info" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>