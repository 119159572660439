<script setup>
import { ref } from "vue";
const showModal = ref(false);
const questionText = ref("");
const questions = ref([]);
const submit = () => {
  questions.value.push(questionText);
  showModal.value = false;
};
</script>

<template>
  <div class="flex-grow">
    <div class="bg-gray-200 w-full h-full p-5">
      <div class="flex justify-between items-center">
        <p class="font-medium mb-5 text-2xl">Employee Handbook</p>

        <div class="flex items-center">
          <base-button @click="showModal = true">I Have A question</base-button>
          <base-modal v-model:show="showModal" @confirm="submit">
            <template #title>Ask A Question</template>
            <template #content>
              <textarea
                name="question"
                v-model="questionText"
                id="question"
                class="w-full border border-gray-500 h-36 p-4"
              ></textarea>
            </template>
            <template #successButton> Submit </template>
          </base-modal>
          <div class="border">
            <span
              class="
                material-icons
                text-white
                border border-blue-500
                bg-blue-400
                cursor-pointer
                rounded-full
              "
            >
              download
            </span>
          </div>
        </div>
      </div>
      <div class="bg-white p-4 rounded-md">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae alias
        molestiae aspernatur qui! Repudiandae consequuntur, nulla inventore
        quibusdam unde culpa alias? Iusto obcaecati exercitationem eius facere
        necessitatibus repellat nemo, laudantium incidunt quas soluta minima
        impedit, praesentium amet nobis hic corrupti! Lorem ipsum, dolor sit
        amet consectetur adipisicing elit. Vitae alias molestiae aspernatur qui!
        Repudiandae consequuntur, nulla inventore quibusdam unde culpa alias?
        Iusto obcaecati exercitationem eius facere necessitatibus repellat nemo,
        laudantium incidunt quas soluta minima impedit, praesentium amet nobis
        hic corrupti! Lorem ipsum, dolor sit amet consectetur adipisicing elit.
        Vitae alias molestiae aspernatur qui! Repudiandae consequuntur, nulla
        inventore quibusdam unde culpa alias? Iusto obcaecati exercitationem
        eius facere necessitatibus repellat nemo, laudantium incidunt quas
        soluta minima impedit, praesentium amet nobis hic corrupti! Lorem ipsum,
        dolor sit amet consectetur adipisicing elit. Vitae alias molestiae
        aspernatur qui! Repudiandae consequuntur, nulla inventore quibusdam unde
        culpa alias? Iusto obcaecati exercitationem eius facere necessitatibus
        repellat nemo, laudantium incidunt quas soluta minima impedit,
        praesentium amet nobis hic corrupti!
      </div>
      <div class="mt-5" v-if="questions.length > 0">
        Questions
        <div
          v-for="item in questions"
          :key="item.index"
          class="
            border border-3 border-gray-400
            bg-white
            py-3
            px-4
            rounded-md
            break-words
          "
        >
          <div class="my-2 text-md">
            <span class="font-medium text-md">Question:</span> {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>