<script setup>
import { defineProps, defineEmits } from "vue";
const emits = defineEmits(["decrease", "increase", "signDocument"]);
const props = defineProps({
  steps: {
    required: true,
    type: Array,
  },
  currentStep: {
    type: Number,
    required: true,
  },
});

const signingPhases = [
  "contractSummary",
  "employeeHandbook",
  "employeeITPolicy",
];
</script>
<template>
  <keep-alive>
    <component :is="props.steps[props.currentStep].component"></component>
  </keep-alive>
  <div class="flex justify-between items-center">
    <div>
      <base-button
        @click="emits('decrease')"
        v-if="props.currentStep > 0"
        backgroundColour="bg-orange hover:bg-orange-light"
        >back</base-button
      >
    </div>
    <div class="">
      <base-button
        v-if="props.currentStep < props.steps.length - 1"
        @click="emits('increase')"
        >Next</base-button
      >

      <base-button
        v-if="signingPhases.includes(props.steps[props.currentStep].title)"
        @click="emits('signDocument', currentStep)"
        >Sign & Continue</base-button
      >
    </div>
  </div>
</template>