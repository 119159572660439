<script setup>
import { defineProps, ref } from "vue";
const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  orientation: {
    type: String,
    default: "vertical",
  },
  borderd: {
    type: Boolean,
    default: false,
  },
});
const activeTab = ref(0);
</script>
<template>
  <div
    class="flex flex-grow"
    :class="{ 'flex-col': props.orientation !== 'vertical' }"
  >
    <div
      class="flex"
      :class="[
        { 'w-full': props.orientation !== 'vertical' },
        { 'flex-col': props.orientation === 'vertical' },
        { 'border-teal border-b': props.borderd },
      ]"
    >
      <div
        v-for="(item, index) in props.tabs"
        :key="item.index"
        @click="activeTab = index"
        class="
          h-10
          px-4
          py-2
          rounded-md
          hover:border-gray-300 hover:bg-gray-100
          cursor-pointer
        "
        :class="[
          { 'w-64': props.orientation === 'vertical' },
          { 'font-medium hover:text-teal': props.borderd },
          { 'text-teal': props.borderdx && activeTab !== index },
          { ' border-gray-200 border': !props.borderd },
          { 'bg-teal text-white': activeTab === index },
        ]"
      >
        {{ item.title }}
      </div>
    </div>
    <p class="font-medium my-4 text-gray-600 text-left text-xl">
      {{ props.tabs[activeTab].text }}
    </p>
    <div
      class="flex flex-grow border rounded-md"
      :class="[{ 'mt-5': props.orientation === 'vertical' }]"
    >
      <component
        :is="
          props.tabs[activeTab].type === 'text'
            ? 'p'
            : props.tabs[activeTab].component
        "
      >
        {{ props.tabs[activeTab].value }}
      </component>
    </div>
  </div>
</template>