<script setup>
import { ref } from "vue";
const showModal = ref(false);
const questionText = ref("");
const questions = ref([]);
const submit = () => {
  questions.value.push(questionText);
  showModal.value = false;
};

const nhsOptions = [
  {
    id: 1,
    option: "yes",
  },
  {
    id: 2,
    option: "no",
  },
];

const nhsCardOptions = [
  {
    id: 1,
    option: "yes",
  },
  {
    id: 2,
    option: "no",
  },
  {
    id: 3,
    option: "Not Applicable",
  },
];
</script>

<template>
  <div class="flex-grow">
    <div class="bg-gray-200 w-full h-full p-5">
      <div class="flex justify-between items-center">
        <p class="font-medium mb-5 text-2xl">Employee IT Policy</p>

        <div class="flex items-center">
          <base-button @click="showModal = true">I Have A question</base-button>
          <base-modal v-model:show="showModal" @confirm="submit">
            <template #title>Ask A Question</template>
            <template #content>
              <textarea
                name="question"
                v-model="questionText"
                id="question"
                class="w-full border border-gray-500 h-36 p-4"
              ></textarea>
            </template>
            <template #successButton> Submit </template>
          </base-modal>
          <div class="border">
            <span
              class="
                material-icons
                text-white
                border border-blue-500
                bg-blue-400
                cursor-pointer
                rounded-full
              "
            >
              download
            </span>
          </div>
        </div>
      </div>
      <div class="bg-white p-4 rounded-md">
        <div class="flex flex-col mr-4">
          <p class="text-xl font-medium">
            Within the previous 12 Months have you been actively employed by the
            NHS?
          </p>
          <div class="form-check" v-for="item in nhsOptions" :key="item.index">
            <input
              class="
                form-check-input
                appearance-none
                rounded-full
                h-4
                w-4
                border border-gray-300
                bg-white
                checked:bg-blue-600 checked:border-blue-600
                focus:outline-none
                transition
                duration-200
                mt-1
                align-top
                bg-no-repeat bg-center bg-contain
                float-left
                mr-2
                cursor-pointer
              "
              type="radio"
              name="flexRadioDefault"
              :value="item.id"
              :id="item.id"
            />
            <label
              class="form-check-label inline-block text-gray-800"
              :for="item.id"
            >
              {{ item.option }}
            </label>
          </div>
        </div>
        <div class="flex flex-col mr-4">
          <p class="text-xl font-medium">Do you have an NHS Smart Card?</p>
          <div
            class="form-check"
            v-for="item in nhsCardOptions"
            :key="item.index"
          >
            <input
              class="
                form-check-input
                appearance-none
                rounded-full
                h-4
                w-4
                border border-gray-300
                bg-white
                checked:bg-blue-600 checked:border-blue-600
                focus:outline-none
                transition
                duration-200
                mt-1
                align-top
                bg-no-repeat bg-center bg-contain
                float-left
                mr-2
                cursor-pointer
              "
              type="radio"
              name="flexRadioDefault"
              :value="item.id"
              :id="item.id"
            />
            <label
              class="form-check-label inline-block text-gray-800"
              :for="item.id"
            >
              {{ item.option }}
            </label>
          </div>
        </div>
        <div class="">
          <p class="text-xl font-medium">
            Relavent IT Documentaiton & Policies.
          </p>
          <p class="text-sm">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
            delectus reiciendis blanditiis perferendis architecto sapiente qui!
            Laudantium in ducimus, odit at dolorem, illo voluptatem, molestias
            ipsam laboriosam quibusdam consectetur nulla maiores reiciendis
            quisquam facere. Consequatur tempora error provident dolorum
            delectus.
          </p>
          <div class="grid grid-cols-12 gap-4 mt-5">
            <div
              class="text-center border border-red-200 p-3 rounded-md"
              v-for="item in 24"
              :key="item"
            >
              <span
                class="
                  material-icons
                  text-red text-5xl
                  cursor-pointer
                  rounded-full
                "
              >
                picture_as_pdf
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5" v-if="questions.length > 0">
        Questions
        <div
          v-for="item in questions"
          :key="item.index"
          class="
            border border-3 border-gray-400
            bg-white
            py-3
            px-4
            rounded-md
            break-words
          "
        >
          <div class="my-2 text-md">
            <span class="font-medium text-md">Question:</span> {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>