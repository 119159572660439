<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import UserProfile from "./User/index";
import Stepper from "@/components/ui/baseComponents/generalStepper.vue";
import WelcomeBoxes from "@/components/layout/generalUser/welcomeBoxes.vue";
import Tabs from "@/components/ui/baseComponents/tabs.vue";
import CarouselTutorial from "./carouselTutorial.vue";
import UserProfileCards from "./userProfile.vue";
import ContractSummary from "./contractSummary.vue";
import EmployeeHandbook from "./employeeHandbook.vue";
import employeeITPolicy from "./employeeITPolicy.vue";
import Swal from "sweetalert2";

const tabs = [
  { title: "tab1tab1", type: "text", value: "lorem501" },
  { title: "tab2tab1", type: "text", value: "lorem502" },
  { title: "tab3tab1", type: "text", value: "lorem503" },
  { title: "tab4tab1", type: "text", value: "lorem504" },
];

const steps = [
  {
    title: "welcome",
    component: WelcomeBoxes,
  },
  {
    title: "items",
    component: () => <Tabs tabs={tabs} orientation="vertical" />,
  },
  { title: "tutorial", component: CarouselTutorial },
  { title: "profile", component: UserProfileCards },
  { title: "contractSummary", component: ContractSummary },
  { title: "employeeHandbook", component: EmployeeHandbook },
  { title: "employeeITPolicy", component: employeeITPolicy },
];
const currentStep = ref(0);

const store = useStore();

const showTutorial = computed(() => {
  return !Boolean(store.getters["auth/userName"].profile?.tutorial_completed);
});

const showAlert = (id) => {
  if (id === 6) {
    store.dispatch("auth/setTutorialAction", { tutorial_completed: 1 });
  }
  Swal.fire({
    title: "Done",
    text: "successfully done the operation",
    icon: "success",
  });
};
</script>
<template>
  <div class="w-full h-full flex flex-col flex-grow">
    <template v-if="showTutorial">
      <Stepper
        :steps="steps"
        :currentStep="currentStep"
        @increase="currentStep++"
        @decrease="currentStep--"
        @signDocument="showAlert"
      ></Stepper>
    </template>
    <template v-else>
      <div class="flex h-full gap-5 w-full">
        <UserProfile />
      </div>
    </template>
  </div>
</template>
