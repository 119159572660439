
<script setup>
import { ref } from "vue";
import Carousel from "@/components/ui/baseComponents/carousel.vue";
const carouselSteps = [
  {
    title: "step123",
    text: "step 1 consists of steps 1 and steps",
    imageURL:
      "https://i.picsum.photos/id/577/200/300.jpg?hmac=iZA0DWSu8zEDIuGdix5l4Jc7RXSJLZ7tR4s25w7Nc8I",
  },
  {
    title: "step2",
    text: "step 2 consists of steps 1 and steps",
    imageURL:
      "https://i.picsum.photos/id/577/200/300.jpg?hmac=iZA0DWSu8zEDIuGdix5l4Jc7RXSJLZ7tR4s25w7Nc8I",
  },
  {
    title: "step3",
    text: "step 3 consists of steps 1 and steps",
    imageURL:
      "https://i.picsum.photos/id/577/200/300.jpg?hmac=iZA0DWSu8zEDIuGdix5l4Jc7RXSJLZ7tR4s25w7Nc8I",
  },
  {
    title: "step4",
    text: "step 4 consists of steps 1 and steps",
    imageURL:
      "https://i.picsum.photos/id/577/200/300.jpg?hmac=iZA0DWSu8zEDIuGdix5l4Jc7RXSJLZ7tR4s25w7Nc8I",
  },
];
const currentStep = ref(0);
</script>
<template>
  <div class="flex flex-grow py-6">
    <div class="carousel flex-grow">
      <Carousel
        :carouselSteps="carouselSteps"
        :currentStep="currentStep"
        @increase="
          currentStep + 1 === carouselSteps.length
            ? (currentStep = 0)
            : currentStep++
        "
        @decrease="
          currentStep - 1 === 0
            ? (currentStep = carouselSteps.length)
            : currentStep--
        "
        @setIndex="(index) => (currentStep = index)"
      />
    </div>
    <div
      class="image-container w-1/4 flex justify-center bg-gray-200 border p-5"
    >
      <p>{{ carouselSteps[currentStep].text }}</p>
    </div>
  </div>
</template>