<script setup>
import { defineProps, defineEmits } from "vue";
import LineChart from "@/components/ui/MA/trainings/Courses/LineChart.vue";
import TableData from "@/components/ui/MA/appraisals/TableData.vue";

const props = defineProps({
  activeTab: {
    type: String,
    required: true,
  },
  TableData: {
    type: Object,
    default: () => {},
  },
  courses: {
    type: Object,
    default: () => {},
  },
});

const emits = defineEmits(["pagination_changed"]);
const tabledropdownOptions = [
  {
    type: "link",
    linkName: "user-view-course",
    paramName: "id",
    label: "View",
  },
];

const tableHeader = [
  { title: "Training Course", prop: "name", id: "name" },
  { title: "Type of Training", prop: "type", id: "type" },
  { title: "Start Date", prop: "pivot.start_date", id: "startDate" },
  { title: "Completion Date", prop: "pivot.due_date", id: "dueDate" },
];

const labelsArray = ["In-progress", "Completed", "Overdue"];
const chartDataArray = [
  props?.courses?.in_progress_courses_count,
  props?.courses?.completed_courses_count,
  props?.courses?.overdue_courses_count,
];

const change = async (page) => {
  emits("pagination_changed", page);
};
</script>
<template>
  <template v-if="props.activeTab === 'trainings'">
    <div class="flex flex-col flex-1 p-2">
      <div class="w-3/4 bg-lightGrey rounded-xl mb-2 mx-auto px-5">
        <h3 class="text-lg text-teal text-center pt-2">
          My Training Courses {{ chartDataArray }}
        </h3>
        <LineChart :labelsArray="labelsArray" :dataArray="chartDataArray" />
      </div>
      <div class="flex-1 overflow-y-auto">
        <p class="text-gray-600 font-medium text-xl px-3">
          My Training Courses
        </p>
        <TableData
          :loading="Boolean(props.TableData?.data)"
          :dropdownOptions="tabledropdownOptions"
          :tableData="props.TableData || []"
          :tableHeader="tableHeader"
          :rowClass="'text-center'"
        />
        <div class="flex justify-center my-5">
          <el-pagination
            background
            layout="pager"
            :total="props.TableData?.total || 20"
            @current-change="change"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </template>
  <div class="p-5 h-full flex flex-1" v-else>
    <div class="flex-1 overflow-y-auto">
      <p class="text-gray-600 font-medium text-xl px-3">
        Historical Training Courses
      </p>
      <TableData
        :loading="Boolean(props.TableData?.data)"
        :dropdownOptions="tabledropdownOptions"
        :tableData="props.TableData?.data || []"
        :tableHeader="tableHeader"
        :rowClass="'text-center'"
      />
      <div class="flex justify-center my-5">
        <el-pagination
          background
          layout="pager"
          :total="props.TableData?.total || 20"
          @current-change="change"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
