<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SingleEditableDetail from "./singleEditableDetail.vue";
const store = useStore();
const user = computed(() => {
  return store.getters["auth/userName"];
});
const cards = [
  {
    title: "Contract Summary",
    fields: [
      {
        title: "Employee type:",
        value: user.value?.contract_summary.employee_type,
      },
      {
        title: "Employee start date:",
        value: user.value?.contract_summary.employee_start_date,
      },
      {
        title: "Contract start date:",
        value: user.value?.contract_summary.contract_start_date,
      },
      {
        title: "Working time pattern:",
        value: user.value?.contract_summary.working_time_pattern,
      },
      {
        title: "Contracted hours per week:",
        value: user.value?.contract_summary.contracted_hours_per_week,
      },
      {
        title: "Min. Leave entitlement:",
        value: user.value?.contract_summary.min_leave_entitlement,
      },
      {
        title: "Contract:",
        value: user.value?.contract_summary.contract_document,
      },
    ],
  },
  {
    title: "Position Information",
    fields: [
      { title: "Job Title:", value: user.value?.position_summary.job_title },
      {
        title: "Contract Type:",
        value: user.value?.position_summary.contract_type,
      },
      { title: "Department:", value: user.value?.position_summary.department },
      {
        title: "Reports to:",
        value: user.value?.position_summary.reports_to,
      },
      {
        title: "Probation end date:",
        value: user.value?.position_summary.probation_end_date,
      },
      {
        title: "Notice Period:",
        value: user.value?.position_summary.notice_period,
      },
    ],
  },
];
</script>

<template>
  <div class="flex-grow">
    <div class="bg-gray-200 w-full h-full p-5">
      <p class="text-xl font-medium mb-5">Contract Information</p>
      <div class="grid grid-cols-2 gap-4">
        <div
          class="bg-white rounded-md p-4 flex flex-col"
          v-for="item in cards"
          :key="item.index"
        >
          <p class="text-md font-medium mb-5">{{ item.title }}</p>
          <div class="flex flex-grow">
            <div class="flex flex-col w-full">
              <div
                class="flex flex-grow justify-between items-center"
                v-for="info in item.fields"
                :key="info.index"
              >
                <SingleEditableDetail :info="info" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>