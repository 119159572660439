<script setup>
const columns = [
  {
    title: "Welcome to ESM",
    text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus tempora
    aperiam, tenetur quaerat quo officiis sapiente, nostrum obcaecati iure, nemo
    voluptatum consequatur fugiat velit ducimus. Quas architecto magnam ratione
    necessitatibus, quaerat odio nihil doloribus recusandae nam, qui velit magni
    maiores.`,
  },
  {
    title: "What is ESM",
    text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus tempora
    aperiam, tenetur quaerat quo officiis sapiente, nostrum obcaecati iure, nemo
    voluptatum consequatur fugiat velit ducimus. Quas architecto magnam ratione
    necessitatibus, quaerat odio nihil doloribus recusandae nam, qui velit magni
    maiores.`,
  },
  {
    title: "Scope of ESM",
    text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus tempora
    aperiam, tenetur quaerat quo officiis sapiente, nostrum obcaecati iure, nemo
    voluptatum consequatur fugiat velit ducimus. Quas architecto magnam ratione
    necessitatibus, quaerat odio nihil doloribus recusandae nam, qui velit magni
    maiores.`,
  },
];
</script>
<template>
  <div class="flex sm:flex-col md:flex-row mb-4 gap-4 flex-grow my-10 px-5">
    <div
      v-for="item in columns"
      :key="item.index"
      class="w-1/3 sm:w-full md:w-1/3 py-5 bg-gray-200 rounded-md"
    >
      <h3 class="font-medium text-2xl text-center">{{ item.title }}</h3>
      <p class="text-sm px-5 mt-5">{{ item.text }}</p>
    </div>
  </div>
</template>